<style lang="scss" scoped>
.box {
  position: fixed;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  .box-main {
    transition: 0.5s all;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding-bottom: 16px;
    overflow: hidden;
    width: 0;
    backdrop-filter: blur(5px);
    .main {
      width: 353px;
      // width: 434px;
      padding-left: 16px;
      padding-right: 16px;
      overflow: hidden;
    }
  }
}

.box-help {
  width: 22px;
  height: 56px;
  position: absolute;
  left: -22px;
  top: 12px;
  background-image: url("../../../../assets/features/hidden.svg");
  cursor: pointer;

  &#show {
    background-image: url("../../../../assets/features/show.svg");
  }
}

#showBox {
  width: 353px;
}

.title {
  margin-top: 14px;
  margin-bottom: 15px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bolder;
  // margin-right: 16px;
}

.header {
  width: 100%;
  display: flex;
  position: relative;
  height: 52px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .header-item {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);

    div {
      width: 100%;
      text-align: center;
    }

    .line {
      width: 100%;
      height: 2px;
      background: #e08c12;
      position: absolute;
      bottom: 0;
      left: 0;
      display: none;
    }

    &#active {
      .line {
        display: block;
      }

      font-weight: 16px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .header-item:nth-child(1) {
    width: 128px;
  }

  .header-item:nth-child(2) {
    width: 156px;
  }
}

.table {
  // margin-top: 10px;

  .table-item:nth-child(even) {
    background: linear-gradient(
      270deg,
      #f0f0f0 0%,
      rgba(240, 240, 240, 0) 100%
    );
  }
  .table-body {
    overflow-y: auto;
    max-height: calc(100vh - 147px - 147px - 63px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .table-item {
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    display: flex;
    // padding-top: 4.5px;
    // padding-bottom: 4.5px;
    position: relative;

    .spec {
      position: absolute;
      left: 0;
      top: 0;
      display: none;
    }

    .row {
      width: 180px;
    }
    .rows {
      // width: 45%;
      white-space: wrap;
      padding-right: 12px;
      animation-name: SSa;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      transform: translateX(100%);
    }

    @keyframes SSa {
      0% {
        opacity: 0;
        transform: translateX(100%);
      }

      100% {
        opacity: 1;
        transform: translateX(0%);
      }
    }

    .row {
      flex-shrink: 0;
      font-weight: 400;
      display: flex;
      align-items: center;
      padding-left: 12px;
      font-size: 13px;
      color: rgb(0, 0, 0, 0.6);

      // overflow: hidden;
      // white-space: wrap;
      // padding-right: 16px;
      span {
        white-space: normal;
        word-wrap: break-word;
        overflow: hidden;
      }
    }
    .row-right {
      // width: 51%;
      width: 189px;
    }
    .row-left {
      // width: 49%;
      width: 213px;
    }
    // .row:nth-child(2) {
    //   // word-break: break-all;
    //   // color: rgba(0, 0, 0, 0.8);
    // }

    .rows {
      font-weight: 700;
      margin-right: 12px;
      width: calc(100% - 170px);
    }
  }
}

.showSize {
  cursor: pointer;
  // right: 114px;
  // top: 64px;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  margin-left: 45px;
  // position: fixed;
  // z-index: 3;
}

#unShowSize {
  background-image: url("../../../../assets/unShowSize.svg");
}

#showSize {
  &:active {
    background-image: url("../../../../assets/showSize-active.svg");
  }
  &:hover {
    background-image: url("../../../../assets/showSize-active.svg");
  }
  background-image: url("../../../../assets/showSize.svg");
}

#left0 {
  right: 0 !important;
}

.header-table {
  background: linear-gradient(90deg, #ff7658 0%, #e70415 100%), #ffffff !important;
  .row {
    font-weight: bold;
    color: white !important;
  }
}

.pagation {
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  .left {
    width: 24px;
    height: 24px;
    background-image: url("./left.svg");
    cursor: pointer;
    background-size: 100% 100%;
    &:hover {
      background-image: url("./left-hover.svg");
      background-size: 100% 100%;
    }
    &:active {
      background-image: url("./left-click.svg");
      background-size: 100% 100%;
    }
  }

  .right {
    transform: rotate(180deg);
  }
  .point {
    margin-left: 24px;
    margin-right: 24px;
    display: flex;

    .points {
      width: 6px;
      background: rgba(0, 0, 0, 0.2);
      height: 6px;
      border-radius: 50%;
      margin-right: 2.5px;
      margin-left: 2.5px;
      &#active {
        background: #e70415;
      }
    }
  }
}
</style>
<template>
  <div class="feature">
    <div
      class="showSize"
      ref="btn"
      @click="showSize"
      :id="isShowSize ? 'unShowSize' : 'showSize'"
    ></div>
    <div class="box" :id="showBox ? '' : 'left0'" v-rc:right>
      <div
        class="box-help"
        @click="showBox = !showBox"
        :id="showBox ? '' : 'show'"
      ></div>
      <div class="box-main" :id="showBox ? 'showBox' : ''">
        <div class="main">
          <div class="title">Basic parameters</div>
          <div class="table">
            <div class="table-item header-table">
              <div class="row" style="white-space:nowrap">
                <span>
                  {{ tableHead[0] }}
                </span>
              </div>
              <div class="row rows" v-if="isShowUi">
                <!-- <span> -->
                {{ tableHead[1] }}
                <!-- </span> -->
              </div>
            </div>
            <div class="table-body">
              <div
                class="table-item"
                :key="index"
                v-for="(item, index) of columns"
              >
                <div class="row" style="white-space:nowrap">
                  <span>
                    {{ item }}
                  </span>
                </div>
                <div class="row rows" v-if="isShowUi">
                  <!-- <span> -->
                  {{ tableData[index + 1] }}
                  <!-- </span> -->
                </div>
              </div>
            </div>
          </div>
          <div class="pagation">
            <!-- {{ pageCurent }} {{ getPageSize }} -->
            <div class="left" @click="lastPage"></div>
            <div class="point">
              <div
                class="points"
                :id="index == pageCurent ? 'active' : ''"
                v-for="(item, index) of getPageSize"
              ></div>
            </div>
            <div class="right left" @click="nextPage"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../../../lib/bus";

export default {
  data() {
    return {
      isShowSize: false,
      showBox: true,
      pageCurent: 0,
      pageSize: 6,
      isShowUi: true,
      columns: [
        "Battery Type",
        "Nominal Battery Voltage (V)",
        "Battery Voltage Range (V)",
        "Max. Continuous Charging/Discharging Current (A)",
        "Max. Charge/Discharge Power (W)",
        "Max. Input Power (W)",
        "Max. Apparent Power Output to Utility Grid (VA)",
        "Output Voltage Range (V)",
        "Back-up Nominal Apparent Power (VA)",
        "Max. Output Current (A)",
        "PV AFCI 3.0",
        "User Interface",
        "Communication with BMS",
      ],
    };
  },
  computed: {
    tableHead() {
      return ["Technical Data", this.tableData[0]];
    },
    tableData() {
      this.isShowUi = false;
      let data = {
        0: [
          "GW6000-ET-20",
          "Li-Ion",
          "500",
          "150~720",
          "30/30",
          "9000/6600",
          "9,600",
          "6,000",
          "0~300",
          "6,000",
          "13.0 (17.4@60sec)",
          "Integrated",
          "LED, WLAN+APP",
          "RS485, CAN",
        ],
        1: [
          "GW8000-ET-20",
          "Li-Ion",
          "500",
          "150~720",
          "30/30",
          "12000/8800",
          "12,800",
          "8,000",
          "0~300",
          "8,000",
          "17.4(23.3@60sec)",
          "Integrated",
          "LED, WLAN+APP",
          "RS485, CAN",
        ],
        // 2: [
        //   "GW9900-ET-20(AUS)",
        //   "Li-Ion",
        //   "500",
        //   "150~720",
        //   "40/40",
        //   "15000/11000",
        //   "16,000",
        //   "9,900",
        //   "0~300",
        //   "10,000",
        //   "21.7(26.1@60sec)",
        //   "Integrated",
        //   "LED, WLAN+APP",
        //   "RS485, CAN",
        // ],
        2: [
          "GW10K-ET-20",
          "Li-Ion",
          "500",
          "150~720",
          "40/40",
          "15000/11000",
          "16,000",
          "10,000",
          "0~300",
          "10,000",
          "21.7(26.1@60sec)",
          "Integrated",
          "LED, WLAN+APP",
          "RS485, CAN",
        ],
        3: [
          "GW12K-ET-20",
          "Li-Ion",
          "500",
          "150~720",
          "40/40",
          "18000/13200",
          "19,200",
          "12,000",
          "0~300",
          "12,000",
          "21.7(26.1@60sec)",
          "Integrated",
          "LED, WLAN+APP",
          "RS485, CAN",
        ],
        4: [
          "GW15K-ET-20",
          "Li-Ion",
          "500",
          "150~720",
          "40/40",
          "24000/16500",
          "24,000",
          "15,000",
          "0~300",
          "15,000",
          "21.7 (26.1@60sec)",
          "Integrated",
          "LED, WLAN+APP",
          "RS485, CAN",
        ],
      };

      setTimeout(() => {
        this.isShowUi = true;
      }, 100);
      return data[this.pageCurent];
    },
    getPageSize() {
      return 5;
    },
    getTableData() {
      return this.tableData.slice(
        this.pageCurent * this.pageSize,
        this.pageCurent * this.pageSize + this.pageSize
      );
    },
  },
  watch: {
    "$store.state.mesh"() {
      this.isShowSize = false;
      bus.$emit("hiddenSize");
    },
  },
  methods: {
    showSize() {
      this.isShowSize = !this.isShowSize;
      if (this.isShowSize) {
        bus.$emit("showSize");
      } else {
        bus.$emit("hiddenSize");
      }
    },
    nextPage() {
      this.pageCurent++;
      if (this.pageCurent == this.getPageSize) {
        this.pageCurent = 0;
      }
    },
    lastPage() {
      this.pageCurent--;
      if (this.pageCurent == -1) {
        this.pageCurent = this.getPageSize - 1;
      }
    },
  },
  mounted() {
    document
      .getElementsByClassName("t-container")[0]
      .insertBefore(
        this.$refs["btn"],
        document.getElementsByClassName("doubleCode")[0]
      );
  },
  beforeDestroy() {
    console.log(this.$refs["btn"]);
    document
      .getElementsByClassName("t-container")[0]
      .removeChild(this.$refs["btn"]);
  },
  created() {},
  destroyed() {
    if (this.isShowSize) {
      bus.$emit("hiddenSize");
    }
  },
};
</script>
